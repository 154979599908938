<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.jpush/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.cardno" size="small" clearable placeholder="按标题查询"></el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="type" title="类型" align="center">
				<template v-slot="{ row }">
					<span>{{types.type[row.type]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="os" title="发送平台" align="center">
				<template v-slot="{ row }">
					<span>{{types.os[row.os]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="send_title" title="发送标题" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="send_msg" title="发送内容" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="fans_ids" title="指定用户" align="center" min-width="100px" />

			<vxe-table-column slot="table-item" title="指定时间" align="center">
				<template v-slot="{ row }">
					{{row.type == 1 ? row.appoint_time :(row.type == 2?row.appoint_hour:'')}}
				</template>
			</vxe-table-column>

			<vxe-table-column slot="table-item" title="操作" align="center">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.jpush/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增极光推送" :visible.sync="addDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="100px" class="form">
				<el-form-item label="推送方式" prop="type">
					<el-radio-group v-model="addDialogFormData.type">
						<el-radio :label="0">即时</el-radio>
						<el-radio :label="1">单次定时</el-radio>
						<el-radio :label="2">每日定时</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="指定时间" prop="appoint_time" v-if="addDialogFormData.type == 1">
					<el-date-picker v-model="addDialogFormData.appoint_time" value-format="yyyy-MM-dd HH:mm:ss"
						type="datetime" placeholder="指定时间" size="small" clearable>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="每日时间" prop="appoint_hour" v-if="addDialogFormData.type == 2">
					<el-time-picker v-model="addDialogFormData.appoint_hour" format='HH:mm' value-format='HH:mm'
						placeholder="任意时间点" size="small" clearable>
					</el-time-picker>
				</el-form-item>
				<el-form-item label="推送目标" prop="os">
					<el-radio-group v-model="addDialogFormData.os">
						<el-radio :label="0">全平台(强)</el-radio>
						<el-radio :label="1">IOS(强)</el-radio>
						<el-radio :label="2">安卓(强)</el-radio>
						<el-radio :label="3">指定用户</el-radio>
						<el-radio :label="4">全平台</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="指定用户" prop="fans_ids" v-if="addDialogFormData.os == 3">
					<el-input v-model="addDialogFormData.fans_ids" type="textarea" rows='3' clearable style="width:80%"
						placeholder="多个用户请用逗号隔开" />
				</el-form-item>
				<el-form-item label="推送标题" prop="send_title">
					<el-input v-model="addDialogFormData.send_title" clearable style="width:80%" />
				</el-form-item>
				<el-form-item label="推送内容" prop="send_msg">
					<el-input v-model="addDialogFormData.send_msg" type="textarea" rows='3' clearable
						style="width:80%" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('market.jpush/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑极光推送" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">

				<el-form-item label="推送方式" prop="type">
					<el-radio-group v-model="editDialogFormData.type">
						<el-radio :label="0">即时</el-radio>
						<el-radio :label="1">单次定时</el-radio>
						<el-radio :label="2">每日定时</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="指定时间" prop="appoint_time" v-if="editDialogFormData.type == 1">
					<el-date-picker v-model="editDialogFormData.appoint_time" value-format="yyyy-MM-dd HH:mm:ss"
						type="datetime" placeholder="指定时间" size="small" clearable>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="每日时间" prop="appoint_hour" v-if="editDialogFormData.type == 2">
					<el-time-picker v-model="editDialogFormData.appoint_hour" format='HH:mm' value-format='HH:mm'
						placeholder="任意时间点" size="small" clearable>
					</el-time-picker>
				</el-form-item>
				<el-form-item label="推送目标" prop="os">
					<el-radio-group v-model="editDialogFormData.os">
						<el-radio :label="0">全平台(强)</el-radio>
						<el-radio :label="1">IOS(强)</el-radio>
						<el-radio :label="2">安卓(强)</el-radio>
						<el-radio :label="3">指定用户</el-radio>
						<el-radio :label="4">全平台</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="指定用户" prop="fans_ids" v-if="editDialogFormData.os == 3">
					<el-input v-model="editDialogFormData.fans_ids" type="textarea" rows='3' clearable style="width:80%"
						placeholder="多个用户请用逗号隔开" />
				</el-form-item>
				<el-form-item label="推送标题" prop="send_title">
					<el-input v-model="editDialogFormData.send_title" clearable style="width:80%" />
				</el-form-item>
				<el-form-item label="推送内容" prop="send_msg">
					<el-input v-model="editDialogFormData.send_msg" type="textarea" rows='3' clearable
						style="width:80%" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					:disabled="editDisabled" v-if="$hasAccess('market.jpush/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'market-jpush-list',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '极光推送',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				types: {
					type: {
						0: '即时',
						1: '单次定时',
						2: '每日定时'
					},
					os: {
						0: '全平台(强推)',
						1: 'IOS(强推)',
						2: "安卓(强推)",
						3: "指定用户",
						4: "全平台"
					},
				},
				// 新增
				addDialogShow: false,
				addDialogFormData: {
					type: 0,
					os: 0,
				},
				rules: {
					send_msg: [{
						required: true,
						message: '请输入发送内容',
						trigger: 'change'
					}],
					send_title: [{
						required: true,
						message: '请输入发送标题',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				editDialogFormOriData: {},
				editNeedData: ['type', 'os', 'send_title', 'send_msg', 'fans_ids', 'appoint_time', 'appoint_hour']
			}
		},
		computed: {
			editDisabled() {
				return false
			},
			viewImgList() {
				return function(row) {
					return [row.urlone]
				}
			},
			viewImgListTwo() {
				return function(row) {
					return [row.urltwo]
				}
			},
		},
		mounted() {},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				const res = await this.$api.Market.GetJpushList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.addDialogFormData = {
					type: 0,
					os: 0,
				}
				this.editDialogFormData = {}
				this.editDialogFormOriData = {}
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields()
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.addDialogShow = true
			},
			// 编辑
			editOne(row) {
				this.editDialogShow = true;
				this.editDialogFormData = Object.assign({}, row);
			},
			// 删除
			deleteOne(row) {
				this.$confirm('是否确定删除该条推送?', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id,
					}
					await this.$api.Market.DeleteJpush(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
			},
			// 新增保存
			saveAdd() {
				if (!this.addDialogFormData.send_title) {
					this.$refs.addForm.validateField('send_title');
					return;
				}
				if (!this.addDialogFormData.send_msg) {
					this.$refs.addForm.validateField('send_msg');
					return;
				}
				this.addAd();
			},
			// 提交记录
			async addAd() {
				const form = {
					type: this.addDialogFormData.type,
					os: this.addDialogFormData.os,
					send_title: this.addDialogFormData.send_title,
					send_msg: this.addDialogFormData.send_msg,
					fans_ids: this.addDialogFormData.fans_ids,
					appoint_time: this.addDialogFormData.appoint_time,
					appoint_hour: this.addDialogFormData.appoint_hour
				}
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(form),
				}
				await this.$api.Market.AddJpush(params);
				this.$notify({
					title: "成功",
					message: "添加成功",
					type: "success",
				});
				this.closeDialog();
				this.getList();
			},
			// 编辑保存
			saveEdit() {
				if (!this.editDialogFormData.send_title) {
					this.$refs.editForm.validateField('send_title');
					return;
				}
				if (!this.editDialogFormData.send_msg) {
					this.$refs.editForm.validateField('send_msg');
					return;
				}
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						await this.$api.Market.EditJpush(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
